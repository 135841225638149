import './HiTech.css'

const HiTech = () => {
    return (
        <nav className="nav-menu">
                <a href=""><img src="/hitech/ht-logo.png" alt="HI-TECH Logo" /></a>
                <ul>
                    <li><a href="services">SERVCIES</a></li>
                    <li><a href="quote">QUOTE</a></li>
                    <li><a href="about">ABOUT</a></li>
                    <li><a href="careers">CAREERS</a></li>
                    <li><a href="contact">CONTACT</a></li>
                </ul>
        </nav>
    );
};
  
export default HiTech;